import React from "react";
import { Link } from "react-router-dom";

export default function btm_1() {
	return (
		<>

			<section className="learnerSupport destinations section-padding pt-2">
				<div className="container">
					<div className="column">
						<div className="heading text-center">
							<span className="subTitle">For queries, feedback &amp; assistance</span>
							<h2>EasyMyShift Helpline Support</h2>
							<p>(24x7)</p>
						</div>
						<div className="row justify-content-center num_detail">
							<div className="col-md-12">
								<div className="box text-center">
									<h3>For Domestic/Commercial Moving</h3>
									<a className="btn theme-btn" href="tel:09812009192"><i className="fa fa-phone"></i> +91 9812009192</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>


			<footer id="footer" className="footer dark-bg">
				<div className="container">
					<div className="footer-top">
						<div className="row">
							<div className="col-lg-3">
								<div className="box">
									<h3>Our Services</h3>
									<ul className="footer-menu list-unstyled">
										<li><Link to="../relocation-services">Relocation Services</Link></li>
										<li><Link to="../car-transportation">Car Transportation</Link></li>
										<li><Link to="../bike-transportation">Bike Transportation</Link></li>
										<li><Link to="../warehousing-services">Warehousing Services</Link></li>
										<li><Link to="../pet-relocation">Pet Relocation</Link></li>
										<li><Link to="../home-domestic-shifting">Home/Domestic Shifting</Link></li>
										<li><Link to="../office-commercial-shifting">Office/Commercial Shifting</Link></li>
									</ul>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="box">
									<h3>Shifting from Chandigarh</h3>
									<ul className="footer-menu list-unstyled">
										<li><Link to="packers-and-movers-chandigarh-to-mumbai">Chandigarh to Mumbai</Link></li>
										<li><Link to="packers-and-movers-chandigarh-to-bangalore">Chandigarh to Bangalore</Link></li>
										<li><Link to="packers-and-movers-chandigarh-to-chennai">Chandigarh to Chennai</Link></li>
										<li><Link to="packers-and-movers-chandigarh-to-hyderabad">Chandigarh to Hyderabad</Link></li>
										<li><Link to="packers-and-movers-chandigarh-to-delhi">Chandigarh to Delhi</Link></li>
										<li><Link to="packers-and-movers-chandigarh-to-kolkata">Chandigarh to Kolkata</Link></li>
										<li><Link to="packers-and-movers-chandigarh-to-indore">Chandigarh to Indore</Link></li>
									</ul>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="box">
									<h3>Important Links</h3>
									<ul className="footer-menu list-unstyled">
										<li><Link to="terms-and-conditions">Terms & Conditions</Link></li>
										<li><Link to="privacy-policy">Privacy Policy</Link></li>
										<li><Link to="about">About Us</Link></li>
										<li><Link to="contact-us">Contact</Link></li>
									</ul>
								</div>
							</div>
							<div className="col-lg-3">
								<div className="box">
									<div className="contactInfo box">
										<p className="text"><strong><img className="mr-2 fade-in" src="images/map-icon/india-flag.png" width="28" height="19" alt="India" /> Address</strong><br /> Shop no-102, Palam Enclave<br />Near Gil Transport,<br />Zirakpur, SAS Nagar Punjab 140603</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="country_list">
						<div className="row">

							<div className="column col-lg-3 col-md-4 col-6">
								<div className="imgBox"><img src="images/map-icon/maharashtra.jpg" width="56" height="56" alt="maharashtra" /></div>
								<div className="content">
									<h3>Maharashtra</h3>
									<ul className="list">
										<li><Link to="packers-and-movers-in-Mumbai">Mumbai</Link></li>
										<li><Link to="packers-and-movers-in-Navi-Mumbai">Navi Mumbai</Link></li>
										<li><Link to="packers-and-movers-in-Pune">Pune</Link></li>
										<li><Link to="packers-and-movers-in-Nagpur">Nagpur</Link></li>
										<li><Link to="packers-and-movers-in-Thane">Thane</Link></li>
										<li><Link to="packers-and-movers-in-Pimpri-Chinchwad">Pimpri Chinchwad</Link></li>
										<li><Link to="packers-and-movers-in-Nashik">Nashik</Link></li>
										<li><Link to="packers-and-movers-in-kalyan-dombivli">Kalyan dombivli</Link></li>
										<li><Link to="packers-and-movers-in-Vasai-virar">Vasai virar</Link></li>
										<li><Link to="packers-and-movers-in-Solapur">Solapur</Link></li>
										<li><Link to="packers-and-movers-in-Bhiwandi">Bhiwandi</Link></li>
										<li><Link to="packers-and-movers-in-Jalgaon">Jalgaon</Link></li>
										<li><Link to="packers-and-movers-in-Akola">Akola</Link></li>
										<li><Link to="packers-and-movers-in-Latur">Latur</Link></li>
										<li><Link to="packers-and-movers-in-thane">Thane</Link></li>
										<li><Link to="packers-and-movers-in-aurangabad">Aurangabad</Link></li>
										<li><Link to="packers-and-movers-in-wardha">Wardha</Link></li>
									</ul>
								</div>
							</div>

							<div className="column col-lg-3 col-md-4 col-6">
								<div className="imgBox"><img src="images/map-icon/west-bengal.jpg" width="56" height="56" alt="West Bengal" /></div>
								<div className="content">
									<h3>Haryana</h3>
									<ul className="list">
										<li><Link to="packers-and-movers-in-ambala">Ambala</Link></li>
										<li><Link to="packers-and-movers-in-hisar">Hisar</Link></li>
										<li><Link to="packers-and-movers-in-panchkula">Panchkula</Link></li>
										<li><Link to="packers-and-movers-in-rohtak">Rohtak</Link></li>
										<li><Link to="packers-and-movers-in-panipat">Panipat</Link></li>
										<li><Link to="packers-and-movers-in-karnal">Karnal</Link></li>
										<li><Link to="packers-and-movers-in-faridabad">Faridabad</Link></li>
										<li><Link to="packers-and-movers-in-gurgaon">Gurgaon</Link></li>
										<li><Link to="packers-and-movers-in-yamunanagar">Yamunanagar</Link></li>
										<li><Link to="packers-and-movers-in-sirsa">Sirsa</Link></li>
										<li><Link to="packers-and-movers-in-sonipat">Sonipat</Link></li>
										<li><Link to="packers-and-movers-in-bhiwani">Bhiwani</Link></li>
										<li><Link to="packers-and-movers-in-bahadurgarh">Bahadurgarh</Link></li>
										<li><Link to="packers-and-movers-in-jind">Jind</Link></li>
										<li><Link to="packers-and-movers-in-thanesar">Thanesar</Link></li>
										<li><Link to="packers-and-movers-in-rewari">Rewari</Link></li>
										<li><Link to="packers-and-movers-in-narnaul">Narnaul</Link></li>
										<li><Link to="packers-and-movers-in-manesar">Manesar</Link></li>
									</ul>
								</div>
							</div>

							<div className="column col-lg-3 col-md-4 col-6">
								<div className="imgBox"><img src="images/map-icon/west-bengal.jpg" width="56" height="56" alt="West Bengal" /></div>
								<div className="content">
									<h3>Punjab</h3>
									<ul className="list">
										<li><Link to="packers-and-movers-in-amritsar">Amritsar</Link></li>
										<li><Link to="packers-and-movers-in-bathinda">Bathinda</Link></li>
										<li><Link to="packers-and-movers-in-gurdaspur">Gurdaspur</Link></li>
										<li><Link to="packers-and-movers-in-jalandhar">Jalandhar</Link></li>
										<li><Link to="packers-and-movers-in-faridkot">Faridkot</Link></li>
										<li><Link to="packers-and-movers-in-kapurthala">Kapurthala</Link></li>
										<li><Link to="packers-and-movers-in-firozpur">Firozpur</Link></li>
										<li><Link to="packers-and-movers-in-ludhiana">Ludhiana</Link></li>
										<li><Link to="packers-and-movers-in-moga">Moga</Link></li>
										<li><Link to="packers-and-movers-in-muktsar">Muktsar</Link></li>
										<li><Link to="packers-and-movers-in-rupnagar">Rupnagar</Link></li>
										<li><Link to="packers-and-movers-in-patiala">Patiala</Link></li>
										<li><Link to="packers-and-movers-in-sangrur">Sangrur</Link></li>
										<li><Link to="packers-and-movers-in-zirakpur">Zirakpur</Link></li>
										<li><Link to="packers-and-movers-in-mohali">Mohali</Link></li>
										<li><Link to="packers-and-movers-in-kharar">Kharar</Link></li>
										<li><Link to="packers-and-movers-in-dera-bassi">Dera Bassi</Link></li>
										<li><Link to="packers-and-movers-in-khanna">Khanna</Link></li>
										<li><Link to="packers-and-movers-in-rajpura">Rajpura</Link></li>
									</ul>
								</div>
							</div>

							<div className="column col-lg-3 col-md-4 col-6">
								<div className="imgBox"><img src="images/map-icon/gujarat.jpg" width="56" height="56" alt="Gujarat" /></div>
								<div className="content">
									<h3>Gujarat</h3>
									<ul className="list">
										<li><Link to="packers-and-movers-in-ahmedabad">Ahmedabad</Link></li>
										<li><Link to="packers-and-movers-in-surat ">Surat </Link></li>
										<li><Link to="packers-and-movers-in-vadodara ">Vadodara </Link></li>
										<li><Link to="packers-and-movers-in-rajkot ">Rajkot </Link></li>
										<li><Link to="packers-and-movers-in-bhavnagar ">Bhavnagar </Link></li>
										<li><Link to="packers-and-movers-in-jamnagar ">Jamnagar </Link></li>
										<li><Link to="packers-and-movers-in-gandhinagar ">Gandhinagar </Link></li>
										<li><Link to="packers-and-movers-in-bharuch ">Bharuch </Link></li>
										<li><Link to="packers-and-movers-in-ankleshwar ">Ankleshwar </Link></li>
										<li><Link to="packers-and-movers-in-vapi">Vapi</Link></li>
										<li><Link to="packers-and-movers-in-surat">Surat</Link></li>
										<li><Link to="packers-and-movers-in-baroda">Baroda</Link></li>
										<li><Link to="packers-and-movers-in-anand">Anand</Link></li>
										<li><Link to="packers-and-movers-in-bhuj">Bhuj</Link></li>
										<li><Link to="packers-and-movers-in-mehsana">Mehsana</Link></li>
										<li><Link to="packers-and-movers-in-valsad">Valsad </Link></li>
									</ul>
								</div>
							</div>
							<div className="column col-lg-3 col-md-4 col-6">
								<div className="imgBox"><img src="images/map-icon/karnataka.jpg" width="56" height="56" alt="Karnataka" loading="lazy" /></div>
								<div className="content">
									<h3>Karnataka</h3>
									<ul className="list">
										<li><Link to="packers-and-movers-in-bangalore">Bangalore</Link></li>
										<li><Link to="packers-and-movers-in-mysore">Mysore</Link></li>
										<li><Link to="packers-and-movers-in-mangalore">Mangalore</Link></li>
										<li><Link to="packers-and-movers-in-belagavi">Belagavi</Link></li>
										<li><Link to="packers-and-movers-in-tumkur">Tumkur</Link></li>
										<li><Link to="packers-and-movers-in-hospet">Hospet</Link></li>
									</ul>
								</div>
							</div>

							<div className="column col-lg-3 col-md-4 col-6">
								<div className="imgBox"><img src="images/map-icon/uttar-pradesh.jpg" width="56" height="56" alt="uttar pradesh" /></div>
								<div className="content">
									<h3>Uttar Pradesh</h3>
									<ul className="list">
										<li><Link to="packers-and-movers-in-kanpur">Kanpur</Link></li>
										<li><Link to="packers-and-movers-in-lucknow">Lucknow</Link></li>
										<li><Link to="packers-and-movers-in-agra">Agra</Link></li>
										<li><Link to="packers-and-movers-in-meerut">Meerut</Link></li>
										<li><Link to="packers-and-movers-in-varanasi">Varanasi</Link></li>
										<li><Link to="packers-and-movers-in-prayagraj">Prayagraj</Link></li>
									</ul>
								</div>
							</div>

							<div className="column col-lg-3 col-md-4 col-6">
								<div className="imgBox"><img src="images/map-icon/west-bengal.jpg" width="56" height="56" alt="West Bengal" /></div>
								<div className="content">
									<h3>West Bengal</h3>
									<ul className="list">
										<li><Link to="packers-and-movers-in-kolkata">Kolkata</Link></li>
										<li><Link to="packers-and-movers-in-asansol">Asansol</Link></li>
										<li><Link to="packers-and-movers-in-siliguri">Siliguri</Link></li>
										<li><Link to="packers-and-movers-in-durgapur">Durgapur</Link></li>
										<li><Link to="packers-and-movers-in-bardhaman">Bardhaman</Link></li>
										<li><Link to="packers-and-movers-in-malda">Malda</Link></li>
									</ul>
								</div>
							</div>

							<div className="column col-lg-3 col-md-4 col-6">
								<div className="imgBox"><img src="images/map-icon/delhi.jpg" width="56" height="56" alt="delhi" /></div>
								<div className="content">
									<h3>Delhi NCR</h3>
									<ul className="list">
										<li><Link to="packers-and-movers-in-new-delhi">New Delhi</Link></li>
										<li><Link to="packers-and-movers-in-gurugram">Gurugram</Link></li>
										<li><Link to="packers-and-movers-in-noida">Noida</Link></li>
										<li><Link to="packers-and-movers-in-delhi">Delhi</Link></li>
										<li><Link to="packers-and-movers-in-faridabad">Faridabad</Link></li>
										<li><Link to="packers-and-movers-in-ghaziabad">Ghaziabad</Link></li>
									</ul>
								</div>
							</div>

							<div className="column col-lg-3 col-md-4 col-6">
								<div className="imgBox"><img src="images/map-icon/west-bengal.jpg" width="56" height="56" alt="West Bengal" /></div>
								<div className="content">
									<h3>Uttar Pradesh</h3>
									<ul className="list">
										<li><Link to="packers-and-movers-in-allahabad">Allahabad</Link></li>
										<li><Link to="packers-and-movers-in-lucknow">Lucknow</Link></li>
										<li><Link to="packers-and-movers-in-kanpur">Kanpur</Link></li>
										<li><Link to="packers-and-movers-in-bareilly">Bareilly</Link></li>
										<li><Link to="packers-and-movers-in-gorakhpur">Gorakhpur</Link></li>
										<li><Link to="packers-and-movers-in-agra">Agra</Link></li>
										<li><Link to="packers-and-movers-in-saharanpur">Saharanpur</Link></li>
										<li><Link to="packers-and-movers-in-ghaziabad">Ghaziabad</Link></li>
										<li><Link to="packers-and-movers-in-jhansi">Jhansi</Link></li>
										<li><Link to="packers-and-movers-in-varanasi">Varanasi</Link></li>
										<li><Link to="packers-and-movers-in-noida">Noida</Link></li>
										<li><Link to="packers-and-movers-in-greater-noida">Greater Noida</Link></li>
									</ul>
								</div>
							</div>

							<div className="column col-lg-3 col-md-4 col-6">
								<div className="imgBox"><img src="images/map-icon/west-bengal.jpg" width="56" height="56" alt="West Bengal" /></div>
								<div className="content">
									<h3>Uttarakhand</h3>
									<ul className="list">
										<li><Link to="packers-and-movers-in-haridwar">Haridwar </Link></li>
										<li><Link to="packers-and-movers-in-dehradun">Dehradun </Link></li>
										<li><Link to="packers-and-movers-in-uttarkashi">Uttarkashi </Link></li>
										<li><Link to="packers-and-movers-in-haldwani">Haldwani </Link></li>
										<li><Link to="packers-and-movers-in-rishikesh">Rishikesh </Link></li>
										<li><Link to="packers-and-movers-in-kashipur">Kashipur </Link></li>
										<li><Link to="packers-and-movers-in-roorki">Roorki</Link></li>
										<li><Link to="packers-and-movers-in-mussoorie">Mussoorie </Link></li>
										<li><Link to="packers-and-movers-in-joshimath">Joshimath </Link></li>
										<li><Link to="packers-and-movers-in-rudraprayag">Rudraprayag </Link></li>
									</ul>
								</div>
							</div>

							<div className="column col-lg-3 col-md-4 col-6">
								<div className="imgBox"><img src="images/map-icon/rajasthan.jpg" width="56" height="56" alt="rajasthan" /></div>
								<div className="content">
									<h3>Rajasthan</h3>
									<ul className="list">
										<li><Link to="packers-and-movers-in-jaipur">Jaipur </Link></li>
										<li><Link to="packers-and-movers-in-pali">Pali</Link></li>
										<li><Link to="packers-and-movers-in-kota">Kota</Link></li>
										<li><Link to="packers-and-movers-in-jodhpur">Jodhpur </Link></li>
										<li><Link to="packers-and-movers-in-ajmer">Ajmer </Link></li>
										<li><Link to="packers-and-movers-in-alwar">Alwar</Link></li>
										<li><Link to="packers-and-movers-in-udaipur">Udaipur </Link></li>
										<li><Link to="packers-and-movers-in-sikar">Sikar </Link></li>
										<li><Link to="packers-and-movers-in-kishangarh">Kishangarh </Link></li>
										<li><Link to="packers-and-movers-in-bikaner">Bikaner </Link></li>
										<li><Link to="packers-and-movers-in-jaisalmer">Jaisalmer </Link></li>
									</ul>
								</div>
							</div>

							<div className="column col-lg-3 col-md-4 col-6">
								<div className="imgBox"><img src="images/map-icon/indore.jpg" width="56" height="56" alt="Indore" /></div>
								<div className="content">
									<h3>Madhya Pradesh</h3>
									<ul className="list">
										<li><Link to="packers-and-movers-in-indore">Indore</Link></li>
										<li><Link to="packers-and-movers-in-bhopal">Bhopal</Link></li>
										<li><Link to="packers-and-movers-in-jabalpur">Jabalpur</Link></li>
										<li><Link to="packers-and-movers-in-gwalior">Gwalior</Link></li>
										<li><Link to="packers-and-movers-in-katni">Katni</Link></li>
										<li><Link to="packers-and-movers-in-ujjain">Ujjain</Link></li>
									</ul>
								</div>
							</div>

							<div className="column col-lg-3 col-md-4 col-6">
								<div className="imgBox"><img src="images/map-icon/west-bengal.jpg" width="56" height="56" alt="West Bengal" /></div>
								<div className="content">
									<h3>Andhra Pradesh</h3>
									<ul className="list">
										<li><Link to="packers-and-movers-in-vishakapatnam">Vishakapatnam </Link></li>
										<li><Link to="packers-and-movers-in-vijaywada">Vijaywada </Link></li>
										<li><Link to="packers-and-movers-in-guntur">Guntur </Link></li>
										<li><Link to="packers-and-movers-in-nellore">Nellore</Link></li>
										<li><Link to="packers-and-movers-in-kurnool">Kurnool</Link></li>
										<li><Link to="packers-and-movers-in-tirupati">Tirupati </Link></li>
									</ul>
								</div>
							</div>

						</div>
					</div>

					<div className="connectUs">
						<div className="row">
							<div className="col-lg-4">
								<div className="box">
									<h3>Follow Us on</h3>
									<div className="socialLinks mt-3">
										<a href="https://www.facebook.com/profile.php?id=61562704051017"><img src="images/facebook.svg" alt="facebook" width="19" height="19" loading="lazy" /></a>
										<a href="#"><img src="images/twitter.svg" alt="twitter" width="19" height="19" loading="lazy" /></a>
										<a href="#"><img src="images/linkedin.svg" alt="linkedin" width="19" height="19" loading="lazy" /></a>
										<a href="#"><img src="images/instagram.svg" alt="instagram" width="19" height="19" loading="lazy" /></a>
										<a href="#"><img src="images/whatsapp.svg" alt="whatsapp" width="19" height="19" loading="lazy" /></a>
									</div>
								</div>
							</div>
							<div className="col-lg-8 mt-5 mt-lg-0">
								<div className="box">
									<h3>Need Help?</h3>
									<div className="contact-info">
										<a href="tel:09812009192"><i className="fa fa-phone"></i> +91 9812009192</a>
										<a href="mailto:info@EasyMyShift.com"><i className="fa fa-envelope"></i> E-mail us your Enquiry</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="copyright">
						<div className="row">
							<div className="col-md-6 text-md-left text-center">
								<p className="m-0">Coopyright © 2024. All Rights Reserved</p>
							</div>
							<div className="col-md-6 text-md-right text-center">
								<ul className="list-unstyled inline">
									<li><Link to="../terms-and-conditions">Terms and Conditions</Link></li>
									<li><Link to="../privacy-policy">Privacy Policy</Link></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</footer>

		</>
	);
}
