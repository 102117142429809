import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import logo from './logo.svg';

import ScrollToTop from './ScrollToTop';

import Header from "./header";
import Footer from "./footer";

import Homepage from "./homepage";
import About from "./about";
import ContactUs from "./contact-us";
import TermsAndConditions from "./terms-and-conditions";
import PrivacyPolicy from "./privacy-policy";

import BikeTransportation from "./bike-transportation";
import CarTransportation from "./car-transportation";
import RelocationServices from "./relocation-services";

import HomeDomesticShifting from "./home-domestic-shifting";
import OfficeCommercialShifting from "./office-commercial-shifting"
import WarehousingServices from "./warehousing-services";

import PackersAgra from "./citywise/packers-and-movers-in-agra";
import PackersAhmedabad from "./citywise/packers-and-movers-in-ahmedabad";
import PackersAjmer from "./citywise/packers-and-movers-in-ajmer";
import PackersAkola from "./citywise/packers-and-movers-in-akola";
import PackersAllahabad from "./citywise/packers-and-movers-in-allahabad";
import PackersAlwar from "./citywise/packers-and-movers-in-alwar";
import PackersAmbala from "./citywise/packers-and-movers-in-ambala";
import PackersAmritsar from "./citywise/packers-and-movers-in-amritsar";
import PackersAnand from "./citywise/packers-and-movers-in-anand";
import PackersAnkleshwar from "./citywise/packers-and-movers-in-ankleshwar";
import PackersAsansol from "./citywise/packers-and-movers-in-asansol";
import PackersAurangabad from "./citywise/packers-and-movers-in-aurangabad";
import PackersBahadurgarh from "./citywise/packers-and-movers-in-bahadurgarh";
import PackersBangalore from "./citywise/packers-and-movers-in-bangalore";
import PackersBardhaman from "./citywise/packers-and-movers-in-bardhaman";
import PackersBareilly from "./citywise/packers-and-movers-in-bareilly";
import PackersBaroda from "./citywise/packers-and-movers-in-baroda";
import PackersBathinda from "./citywise/packers-and-movers-in-bathinda";
import PackersBelagavi from "./citywise/packers-and-movers-in-belagavi";
import PackersBharuch from "./citywise/packers-and-movers-in-bharuch";
import PackersBhavnagar from "./citywise/packers-and-movers-in-bhavnagar";
import PackersBhiwandi from "./citywise/packers-and-movers-in-bhiwandi";
import PackersBhiwani from "./citywise/packers-and-movers-in-bhiwani";
import PackersBhopal from "./citywise/packers-and-movers-in-bhopal";
import PackersBhuj from "./citywise/packers-and-movers-in-bhuj";
import PackersBikaner from "./citywise/packers-and-movers-in-bikaner";
import PackersDehradun from "./citywise/packers-and-movers-in-dehradun";
import PackersDelhi from "./citywise/packers-and-movers-in-delhi";
import PackersDeraBassi from "./citywise/packers-and-movers-in-dera-bassi";
import PackersDurgapur from "./citywise/packers-and-movers-in-durgapur";
import PackersFaridabad from "./citywise/packers-and-movers-in-faridabad";
import PackersFaridkot from "./citywise/packers-and-movers-in-faridkot";
import PackersFirozpur from "./citywise/packers-and-movers-in-firozpur";
import PackersGandhinagar from "./citywise/packers-and-movers-in-gandhinagar";
import PackersGhaziabad from "./citywise/packers-and-movers-in-ghaziabad";
import PackersGoa from "./citywise/packers-and-movers-in-goa";
import PackersGorakhpur from "./citywise/packers-and-movers-in-gorakhpur";
import PackersGreaterNoida from "./citywise/packers-and-movers-in-greater-noida";
import PackersGuntur from "./citywise/packers-and-movers-in-guntur";
import PackersGurdaspur from "./citywise/packers-and-movers-in-gurdaspur";
import PackersGurgaon from "./citywise/packers-and-movers-in-gurgaon";
import PackersGurugram from "./citywise/packers-and-movers-in-gurugram";
import PackersGwalior from "./citywise/packers-and-movers-in-gwalior";
import PackersHaldwani from "./citywise/packers-and-movers-in-haldwani";
import PackersHaridwar from "./citywise/packers-and-movers-in-haridwar";
import PackersHisar from "./citywise/packers-and-movers-in-hisar";
import PackersHospet from "./citywise/packers-and-movers-in-hospet";
import PackersIndore from "./citywise/packers-and-movers-in-indore";
import PackersJabalpur from "./citywise/packers-and-movers-in-jabalpur";
import PackersJaipur from "./citywise/packers-and-movers-in-jaipur";
import PackersJaisalmer from "./citywise/packers-and-movers-in-jaisalmer";
import PackersJalandhar from "./citywise/packers-and-movers-in-jalandhar";
import PackersJalgaon from "./citywise/packers-and-movers-in-jalgaon";
import PackersJamnagar from "./citywise/packers-and-movers-in-jamnagar";
import PackersJhansi from "./citywise/packers-and-movers-in-jhansi";
import PackersJind from "./citywise/packers-and-movers-in-jind";
import PackersJodhpur from "./citywise/packers-and-movers-in-jodhpur";
import PackersJoshimath from "./citywise/packers-and-movers-in-joshimath";
import PackersKalyanDombivli from "./citywise/packers-and-movers-in-kalyan-dombivli";
import PackersKanpur from "./citywise/packers-and-movers-in-kanpur";
import PackersKapurthala from "./citywise/packers-and-movers-in-kapurthala";
import PackersKarnal from "./citywise/packers-and-movers-in-karnal";
import PackersKashipur from "./citywise/packers-and-movers-in-kashipur";
import PackersKatni from "./citywise/packers-and-movers-in-katni";
import PackersKhanna from "./citywise/packers-and-movers-in-khanna";
import PackersKharar from "./citywise/packers-and-movers-in-kharar";
import PackersKishangarh from "./citywise/packers-and-movers-in-kishangarh";
import PackersKolkata from "./citywise/packers-and-movers-in-kolkata";
import PackersKota from "./citywise/packers-and-movers-in-kota";
import PackersKurnool from "./citywise/packers-and-movers-in-kurnool";
import PackersLatur from "./citywise/packers-and-movers-in-latur";
import PackersLucknow from "./citywise/packers-and-movers-in-lucknow";
import PackersLudhiana from "./citywise/packers-and-movers-in-ludhiana";
import PackersMalda from "./citywise/packers-and-movers-in-malda";
import PackersManesar from "./citywise/packers-and-movers-in-manesar";
import PackersMangalore from "./citywise/packers-and-movers-in-mangalore";
import PackersMeerut from "./citywise/packers-and-movers-in-meerut";
import PackersMehsana from "./citywise/packers-and-movers-in-mehsana";
import PackersMoga from "./citywise/packers-and-movers-in-moga";
import PackersMohali from "./citywise/packers-and-movers-in-mohali";
import PackersMuktsar from "./citywise/packers-and-movers-in-muktsar";
import PackersMumbai from "./citywise/packers-and-movers-in-mumbai";
import PackersMussoorie from "./citywise/packers-and-movers-in-mussoorie";
import PackersMysore from "./citywise/packers-and-movers-in-mysore";
import PackersNagpur from "./citywise/packers-and-movers-in-nagpur";
import PackersNarnaul from "./citywise/packers-and-movers-in-narnaul";
import PackersNashik from "./citywise/packers-and-movers-in-nashik";
import PackersNaviMumbai from "./citywise/packers-and-movers-in-navi-mumbai";
import PackersNellore from "./citywise/packers-and-movers-in-nellore";
import PackersNewDelhi from "./citywise/packers-and-movers-in-new-delhi";
import PackersNoida from "./citywise/packers-and-movers-in-noida";
import PackersPali from "./citywise/packers-and-movers-in-pali";
import PackersPanchkula from "./citywise/packers-and-movers-in-panchkula";
import PackersPanipat from "./citywise/packers-and-movers-in-panipat";
import PackersPatiala from "./citywise/packers-and-movers-in-patiala";
import PackersPimpriChinchwad from "./citywise/packers-and-movers-in-pimpri-chinchwad";
import PackersPrayagraj from "./citywise/packers-and-movers-in-prayagraj";
import PackersPune from "./citywise/packers-and-movers-in-pune";
import PackersRajkot from "./citywise/packers-and-movers-in-rajkot";
import PackersRajpura from "./citywise/packers-and-movers-in-rajpura";
import PackersRewari from "./citywise/packers-and-movers-in-rewari";
import PackersRishikesh from "./citywise/packers-and-movers-in-rishikesh";
import PackersRohtak from "./citywise/packers-and-movers-in-rohtak";
import PackersRoorkee from "./citywise/packers-and-movers-in-roorkee";
import PackersRopar from "./citywise/packers-and-movers-in-ropar";
import PackersRudraprayag from "./citywise/packers-and-movers-in-rudraprayag";
import PackersRudrapur from "./citywise/packers-and-movers-in-rudrapur";
import PackersRupnagar from "./citywise/packers-and-movers-in-rupnagar";
import PackersSaharanpur from "./citywise/packers-and-movers-in-saharanpur";
import PackersSangrur from "./citywise/packers-and-movers-in-sangrur";
import PackersSatna from "./citywise/packers-and-movers-in-satna";
import PackersShimla from "./citywise/packers-and-movers-in-shimla";
import PackersSikar from "./citywise/packers-and-movers-in-sikar";
import PackersSilchar from "./citywise/packers-and-movers-in-silchar";
import PackersSiliguri from "./citywise/packers-and-movers-in-siliguri";
import PackersSirsa from "./citywise/packers-and-movers-in-sirsa";
import PackersSolan from "./citywise/packers-and-movers-in-solan";
import PackersSonipat from "./citywise/packers-and-movers-in-sonipat";
import PackersSolapur from "./citywise/packers-and-movers-in-solapur";
import PackersSrinagar from "./citywise/packers-and-movers-in-srinagar";
import PackersSurat from "./citywise/packers-and-movers-in-surat";
import PackersThane from "./citywise/packers-and-movers-in-thane";
import PackersThanesar from "./citywise/packers-and-movers-in-thanesar";
import PackersTirupati from "./citywise/packers-and-movers-in-tirupati";
import PackersTumkur from "./citywise/packers-and-movers-in-tumkur";
import PackersUdaipur from "./citywise/packers-and-movers-in-udaipur";
import PackersUdhampur from "./citywise/packers-and-movers-in-udhampur";
import PackersUjjain from "./citywise/packers-and-movers-in-ujjain";
import PackersUttarkashi from "./citywise/packers-and-movers-in-uttarkashi";
import PackersVadodara from "./citywise/packers-and-movers-in-vadodara";
import PackersValsad from "./citywise/packers-and-movers-in-valsad";
import PackersVaranasi from "./citywise/packers-and-movers-in-varanasi";
import PackersVapi from "./citywise/packers-and-movers-in-vapi";
import PackersVasaiVirar from "./citywise/packers-and-movers-in-vasai-virar";
import PackersVijaywada from "./citywise/packers-and-movers-in-vijaywada";
import PackersVishakapatnam from "./citywise/packers-and-movers-in-vishakapatnam";
import PackersWarangal from "./citywise/packers-and-movers-in-warangal";
import PackersWardha from "./citywise/packers-and-movers-in-wardha";
import PackersYamunanagar from "./citywise/packers-and-movers-in-yamunanagar";
import PackersZirakpur from "./citywise/packers-and-movers-in-zirakpur";

// citywise pages ended
import './App.css';

function App() {
  return (
    <>
      <Router>
      <ScrollToTop />
        <Header />
        <Routes>
        <Route path="/" element={<Homepage />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/terms-and-conditions" element={<TermsAndConditions />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/bike-transportation" element={<BikeTransportation />}></Route>
          <Route path="/car-transportation" element={<CarTransportation />}></Route>
          <Route path="/relocation-services" element={<RelocationServices />}></Route>

          <Route path="/home-domestic-shifting" element={<HomeDomesticShifting />}></Route>
          <Route path="/office-commercial-shifting" element={<OfficeCommercialShifting />}></Route>
          <Route path="/warehousing-services" element={<WarehousingServices />}></Route>

          <Route path="/packers-and-movers-in-agra" element={<PackersAgra />}></Route>
          <Route path="/packers-and-movers-in-ahmedabad" element={<PackersAhmedabad />}></Route>
          <Route path="/packers-and-movers-in-ajmer" element={<PackersAjmer />}></Route>
          <Route path="/packers-and-movers-in-akola" element={<PackersAkola />}></Route>
          <Route path="/packers-and-movers-in-allahabad" element={<PackersAllahabad />}></Route>
          <Route path="/packers-and-movers-in-alwar" element={<PackersAlwar />}></Route>
          <Route path="/packers-and-movers-in-ambala" element={<PackersAmbala />}></Route>
          <Route path="/packers-and-movers-in-amritsar" element={<PackersAmritsar />}></Route>
          <Route path="/packers-and-movers-in-anand" element={<PackersAnand />}></Route>
          <Route path="/packers-and-movers-in-ankleshwar" element={<PackersAnkleshwar />}></Route>
          <Route path="/packers-and-movers-in-asansol" element={<PackersAsansol />}></Route>
          <Route path="/packers-and-movers-in-aurangabad" element={<PackersAurangabad />}></Route>
          <Route path="/packers-and-movers-in-bahadurgarh" element={<PackersBahadurgarh />}></Route>
          <Route path="/packers-and-movers-in-bangalore" element={<PackersBangalore />}></Route>
          <Route path="/packers-and-movers-in-bardhaman" element={<PackersBardhaman />}></Route>
          <Route path="/packers-and-movers-in-bareilly" element={<PackersBareilly />}></Route>
          <Route path="/packers-and-movers-in-baroda" element={<PackersBaroda />}></Route>
          <Route path="/packers-and-movers-in-bathinda" element={<PackersBathinda />}></Route>
          <Route path="/packers-and-movers-in-belagavi" element={<PackersBelagavi />}></Route>
          <Route path="/packers-and-movers-in-bharuch" element={<PackersBharuch />}></Route>
          <Route path="/packers-and-movers-in-bhavnagar" element={<PackersBhavnagar />}></Route>
          <Route path="/packers-and-movers-in-bhiwandi" element={<PackersBhiwandi />}></Route>
          <Route path="/packers-and-movers-in-bhiwani" element={<PackersBhiwani />}></Route>
          <Route path="/packers-and-movers-in-bhopal" element={<PackersBhopal />}></Route>
          <Route path="/packers-and-movers-in-bhuj" element={<PackersBhuj />}></Route>
          <Route path="/packers-and-movers-in-bikaner" element={<PackersBikaner />}></Route>
          <Route path="/packers-and-movers-in-dehradun" element={<PackersDehradun />}></Route>
          <Route path="/packers-and-movers-in-delhi" element={<PackersDelhi />}></Route>
          <Route path="/packers-and-movers-in-dera-bassi" element={<PackersDeraBassi />}></Route>
          <Route path="/packers-and-movers-in-durgapur" element={<PackersDurgapur />}></Route>2056
          <Route path="/packers-and-movers-in-faridabad" element={<PackersFaridabad />}></Route>
          <Route path="/packers-and-movers-in-faridkot" element={<PackersFaridkot />}></Route>
          <Route path="/packers-and-movers-in-firozpur" element={<PackersFirozpur />}></Route>
          <Route path="/packers-and-movers-in-gandhinagar" element={<PackersGandhinagar />}></Route>
          <Route path="/packers-and-movers-in-ghaziabad" element={<PackersGhaziabad />}></Route>
          <Route path="/packers-and-movers-in-goa" element={<PackersGoa />}></Route>
          <Route path="/packers-and-movers-in-gorakhpur" element={<PackersGorakhpur />}></Route>
          <Route path="/packers-and-movers-in-greater-noida" element={<PackersGreaterNoida />}></Route>
          <Route path="/packers-and-movers-in-guntur" element={<PackersGuntur />}></Route>
          <Route path="/packers-and-movers-in-gurdaspur" element={<PackersGurdaspur />}></Route>
          <Route path="/packers-and-movers-in-gurgaon" element={<PackersGurgaon />}></Route>
          <Route path="/packers-and-movers-in-gurugram" element={<PackersGurugram />}></Route>
          <Route path="/packers-and-movers-in-gwalior" element={<PackersGwalior />}></Route>
          <Route path="/packers-and-movers-in-haldwani" element={<PackersHaldwani />}></Route>
          <Route path="/packers-and-movers-in-haridwar" element={<PackersHaridwar />}></Route>
          <Route path="/packers-and-movers-in-hisar" element={<PackersHisar />}></Route>
          <Route path="/packers-and-movers-in-hospet" element={<PackersHospet />}></Route>
          <Route path="/packers-and-movers-in-indore" element={<PackersIndore />}></Route>
          <Route path="/packers-and-movers-in-jabalpur" element={<PackersJabalpur />}></Route>
          <Route path="/packers-and-movers-in-jaipur" element={<PackersJaipur />}></Route>
          <Route path="/packers-and-movers-in-jaisalmer" element={<PackersJaisalmer />}></Route>
          <Route path="/packers-and-movers-in-jalandhar" element={<PackersJalandhar />}></Route>
          <Route path="/packers-and-movers-in-jalgaon" element={<PackersJalgaon />}></Route>
          <Route path="/packers-and-movers-in-jamnagar" element={<PackersJamnagar />}></Route>
          <Route path="/packers-and-movers-in-jhansi" element={<PackersJhansi />}></Route>
          <Route path="/packers-and-movers-in-jind" element={<PackersJind />}></Route>
          <Route path="/packers-and-movers-in-jodhpur" element={<PackersJodhpur />}></Route>
          <Route path="/packers-and-movers-in-joshimath" element={<PackersJoshimath />}></Route>
          <Route path="/packers-and-movers-in-kalyan-dombivli" element={<PackersKalyanDombivli />}></Route>
          <Route path="/packers-and-movers-in-kanpur" element={<PackersKanpur />}></Route>
          <Route path="/packers-and-movers-in-kapurthala" element={<PackersKapurthala />}></Route>
          <Route path="/packers-and-movers-in-karnal" element={<PackersKarnal />}></Route>
          <Route path="/packers-and-movers-in-kashipur" element={<PackersKashipur />}></Route>
          <Route path="/packers-and-movers-in-katni" element={<PackersKatni />}></Route>
          <Route path="/packers-and-movers-in-khanna" element={<PackersKhanna />}></Route>
          <Route path="/packers-and-movers-in-kharar" element={<PackersKharar />}></Route>
          <Route path="/packers-and-movers-in-kishangarh" element={<PackersKishangarh />}></Route>
          <Route path="/packers-and-movers-in-kolkata" element={<PackersKolkata />}></Route>
          <Route path="/packers-and-movers-in-kota" element={<PackersKota />}></Route>
          <Route path="/packers-and-movers-in-kurnool" element={<PackersKurnool />}></Route>
          <Route path="/packers-and-movers-in-latur" element={<PackersLatur />}></Route>
          <Route path="/packers-and-movers-in-lucknow" element={<PackersLucknow />}></Route>
          <Route path="/packers-and-movers-in-ludhiana" element={<PackersLudhiana />}></Route>
          <Route path="/packers-and-movers-in-malda" element={<PackersMalda />}></Route>
          <Route path="/packers-and-movers-in-manesar" element={<PackersManesar />}></Route>
          <Route path="/packers-and-movers-in-mangalore" element={<PackersMangalore />}></Route>
          <Route path="/packers-and-movers-in-meerut" element={<PackersMeerut />}></Route>
          <Route path="/packers-and-movers-in-mehsana" element={<PackersMehsana />}></Route>
          <Route path="/packers-and-movers-in-moga" element={<PackersMoga />}></Route>
          <Route path="/packers-and-movers-in-mohali" element={<PackersMohali />}></Route>
          <Route path="/packers-and-movers-in-muktsar" element={<PackersMuktsar />}></Route>
          <Route path="/packers-and-movers-in-mumbai" element={<PackersMumbai />}></Route>
          <Route path="/packers-and-movers-in-mussoorie" element={<PackersMussoorie />}></Route>
          <Route path="/packers-and-movers-in-mysore" element={<PackersMysore />}></Route>
          <Route path="/packers-and-movers-in-nagpur" element={<PackersNagpur />}></Route>
          <Route path="/packers-and-movers-in-narnaul" element={<PackersNarnaul />}></Route>
          <Route path="/packers-and-movers-in-nashik" element={<PackersNashik />}></Route>
          <Route path="/packers-and-movers-in-navi-mumbai" element={<PackersNaviMumbai />}></Route>
          <Route path="/packers-and-movers-in-nellore" element={<PackersNellore />}></Route>
          <Route path="/packers-and-movers-in-new-delhi" element={<PackersNewDelhi />}></Route>
          <Route path="/packers-and-movers-in-noida" element={<PackersNoida />}></Route>
          <Route path="/packers-and-movers-in-pali" element={<PackersPali />}></Route>
          <Route path="/packers-and-movers-in-panchkula" element={<PackersPanchkula />}></Route>
          <Route path="/packers-and-movers-in-panipat" element={<PackersPanipat />}></Route>
          <Route path="/packers-and-movers-in-patiala" element={<PackersPatiala />}></Route>
          <Route path="/packers-and-movers-in-pimpri-chinchwad" element={<PackersPimpriChinchwad />}></Route>
          <Route path="/packers-and-movers-in-prayagraj" element={<PackersPrayagraj />}></Route>
          <Route path="/packers-and-movers-in-pune" element={<PackersPune />}></Route>
          <Route path="/packers-and-movers-in-rajkot" element={<PackersRajkot />}></Route>
          <Route path="/packers-and-movers-in-rajpura" element={<PackersRajpura />}></Route>
          <Route path="/packers-and-movers-in-rewari" element={<PackersRewari />}></Route>
          <Route path="/packers-and-movers-in-rishikesh" element={<PackersRishikesh />}></Route>
          <Route path="/packers-and-movers-in-roorkee" element={<PackersRoorkee />}></Route>
          <Route path="/packers-and-movers-in-ropar" element={<PackersRopar />}></Route>
          <Route path="/packers-and-movers-in-rudraprayag" element={<PackersRudraprayag />}></Route>
          <Route path="/packers-and-movers-in-rudrapur" element={<PackersRudrapur />}></Route>
          <Route path="/packers-and-movers-in-rupnagar" element={<PackersRupnagar />}></Route>
          <Route path="/packers-and-movers-in-saharanpur" element={<PackersSaharanpur />}></Route>
          <Route path="/packers-and-movers-in-sangrur" element={<PackersSangrur />}></Route>
          <Route path="/packers-and-movers-in-sikar" element={<PackersSikar />}></Route>
          <Route path="/packers-and-movers-in-silchar" element={<PackersSilchar />}></Route>
          <Route path="/packers-and-movers-in-satna" element={<PackersSatna />}></Route>
          <Route path="/packers-and-movers-in-shimla" element={<PackersShimla />}></Route>
          <Route path="/packers-and-movers-in-siliguri" element={<PackersSiliguri />}></Route>
          <Route path="/packers-and-movers-in-sirsa" element={<PackersSirsa />}></Route>
          <Route path="/packers-and-movers-in-solan" element={<PackersSolan />}></Route>
          <Route path="/packers-and-movers-in-solapur" element={<PackersSolapur />}></Route>
          <Route path="/packers-and-movers-in-sonipat" element={<PackersSonipat />}></Route>
          <Route path="/packers-and-movers-in-srinagar" element={<PackersSrinagar />}></Route>
          <Route path="/packers-and-movers-in-surat" element={<PackersSurat />}></Route>
          <Route path="/packers-and-movers-in-thane" element={<PackersThane />}></Route>
          <Route path="/packers-and-movers-in-thanesar" element={<PackersThanesar />}></Route>
          <Route path="/packers-and-movers-in-tirupati" element={<PackersTirupati />}></Route>
          <Route path="/packers-and-movers-in-tumkur" element={<PackersTumkur />}></Route>
          <Route path="/packers-and-movers-in-udaipur" element={<PackersUdaipur />}></Route>
          <Route path="/packers-and-movers-in-udhampur" element={<PackersUdhampur />}></Route>
          <Route path="/packers-and-movers-in-ujjain" element={<PackersUjjain />}></Route>
          <Route path="/packers-and-movers-in-uttarkashi" element={<PackersUttarkashi />}></Route>
          <Route path="/packers-and-movers-in-vadodara" element={<PackersVadodara />}></Route>
          <Route path="/packers-and-movers-in-valsad" element={<PackersValsad />}></Route>
          <Route path="/packers-and-movers-in-vapi" element={<PackersVapi />}></Route>
          <Route path="/packers-and-movers-in-vijaywada" element={<PackersVijaywada />}></Route>
          <Route path="/packers-and-movers-in-varanasi" element={<PackersVaranasi />}></Route>
          <Route path="/packers-and-movers-in-vasai-virar" element={<PackersVasaiVirar />}></Route>
          <Route path="/packers-and-movers-in-vijaywada" element={<PackersVijaywada />}></Route>
          <Route path="/packers-and-movers-in-vishakapatnam" element={<PackersVishakapatnam />}></Route>
          <Route path="/packers-and-movers-in-warangal" element={<PackersWarangal />}></Route>
          <Route path="/packers-and-movers-in-wardha" element={<PackersWardha />}></Route>
          <Route path="/packers-and-movers-in-yamunanagar" element={<PackersYamunanagar />}></Route>
          <Route path="/packers-and-movers-in-zirakpur" element={<PackersZirakpur />}></Route>
		  
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
